<template>
  <div>
    <getecma-form v-if="city" :submit="create">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="city.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Itajaí" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="city.federative_unit"
            label="Unidade Federativa (UF)*"
            name="unidade federativa"
            type="text"
            rules="required"
            placeholder="ex.: SC" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="city.citys_anniversary"
            label="Aniversário da Cidade*"
            name="aniversário da cidade"
            type="date"
            rules="required"
            placeholder="ex.: 17/05/2002" />
        </div>
      </div>
      <div class="mt--xl mb--md d--flex justify-content-end">
        <getecma-button
          class="fs--xs"
          :round="false"
          size="lg"
          @click="goHistoryBack()">
          Cancelar
        </getecma-button>
        <getecma-button
          native-type="submit"
          class="fs--xs ms--md"
          :round="false"
          bg-color="success"
          size="lg">
          Criar
        </getecma-button>
      </div>
    </getecma-form>
  </div>
</template>

<script>
import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'GetecmaCityCreateInformation',
  components: {
  },
  inject: ['cityEditVm'],
  data() {
    return {
      city: this.cityEditVm.city,
      performer: getters.getUser(),
    };
  },
  methods: {
    goHistoryBack,
    create() {
      this.$emit('create');
    },
  },
};
</script>
