<template>
  <getecma-single-content-layout v-loading="fullscreenLoading" content-class="scroll pe--xxl">
    <template v-if="city" #content>
      <getecma-breadcrumb :items="itemsBreadcrumb" class="mb--md mt--xl" />
      <div v-if="city.id">
        <getecma-header size="lg">Editar Cidade</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-city-edit-information @save="save" />
      </div>
      <div v-if="!city_id">
        <getecma-header size="lg">Adicionar Cidade</getecma-header>
        <getecma-divider class="my--md" />
        <getecma-city-create-information @create="save" />
      </div>
    </template>
  </getecma-single-content-layout>
</template>

<script>
import { toastError, toastSuccess } from '@/services/toastService';
import { goToOpenCity, goToCityForbiddenPage } from '@/modules/city/city.routes'; // eslint-disable-line
import { getters } from '@/modules/user/user.store';
import { getCityById, updateCityInformation, createNewCity } from '@/modules/city/city.service';
import { USERS_ROLES } from '@/modules/user/user.constants';
import { CITIES_URL } from '@/modules/city/city.constants';

import GetecmaSingleContentLayout from '@/layouts/SingleContentLayout.vue';
import GetecmaCityEditInformation from '@/modules/city/components/CityEditComponent.vue';
import GetecmaCityCreateInformation from '@/modules/city/components/CityCreateComponent.vue';
import GetecmaBreadcrumb from '@/components/breadcrumb/Breadcrumb.vue';
import GetecmaDivider from '@/components/menu/components/Divider.vue';

export default {
  name: 'GetecmaCityEditPage',
  components: {
    GetecmaSingleContentLayout,
    GetecmaCityEditInformation,
    GetecmaCityCreateInformation,
    GetecmaBreadcrumb,
    GetecmaDivider,
  },
  provide() {
    const cityEditVm = {};
    Object.defineProperty(cityEditVm, 'city', {
      get: () => this.city,
    });
    return { cityEditVm };
  },
  data() {
    return {
      itemsBreadcrumb: [
        { name: 'Dashboard', path: '/' },
        { name: 'Cidades', path: CITIES_URL.path },
        { name: 'Adicionar', path: this.$route.path },
      ],
      currentUser: getters.getUser(),
      city: null,
      createdCity: null,
      fullscreenLoading: false,
    };
  },
  computed: {
    city_id() {
      return this.$route.params?.id;
    },
  },
  mounted() {
    if (this.city_id) {
      this.itemsBreadcrumb[2].name = 'Editar';
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToCityForbiddenPage(this.$router, false);
      this.onFetch();
    } else {
      if (this.currentUser.permission < USERS_ROLES.ADMIN) goToCityForbiddenPage(this.$router, true);
      this.onFetch();
    }
  },
  methods: {
    onFetch() {
      if (this.city_id) {
        getCityById(this.city_id)
          .then(data => {
            this.city = data;
          })
          .catch(() => toastError('Erro ao obter cidade por ID'));
      } else {
        this.city = {
          name: '',
        };
      }
    },
    save() {
      if (this.city_id) {
        this.fullscreenLoading = true;
        updateCityInformation(this.city)
          .then(data => {
            this.fullscreenLoading = false;
            toastSuccess('Cidade salva!');
            goToOpenCity(this.$router, data);
          })
          .catch(() => {
            toastError('Erro ao atualizar o perfil da cidade');
            this.fullscreenLoading = false;
          });
      } else {
        this.onCreateNewCity(this.city);
      }
    },
    onCreateNewCity(city) {
      createNewCity(city)
        .then(data => {
          goToOpenCity(this.$router, data);
        })
        .catch(() => {
          toastError('Erro ao salvar a cidade');
        });
    },
  },
};
</script>
