<template>
  <getecma-form v-if="city" :submit="save">

    <fieldset class="form-group mt--xl">
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="city.name"
            label="Nome*"
            name="nome"
            type="text"
            rules="required"
            placeholder="ex.: Itajaí" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
          <getecma-input
            v-model="city.federative_unit"
            label="Unidade Federativa (UF)*"
            name="unidade federativa"
            type="text"
            rules="required"
            placeholder="ex.: SC" />
        </div>
      </div>
      <div class="row mt--xl">
        <div class="form-field col-18 col-lg-9">
            <getecma-input
            v-model="city_anniversary"
            label="Aniversário da Cidade*"
            name="aniversário da cidade"
            type="date"
            rules="required"
            placeholder="ex.: 17/05/2002" />
        </div>
      </div>
    </fieldset>
    <div class="mt--xl mb--md d--flex justify-content-end">
      <getecma-button
        size="xl"
        :round="false"
        class="fs--sm"
        @click="goHistoryBack()">
        Cancelar
      </getecma-button>
      <getecma-button
        native-type="submit"
        class="ms--md fs--sm"
        bg-color="success"
        :round="false"
        size="xl">
        Salvar
      </getecma-button>
    </div>
  </getecma-form>
</template>

<script>

import { goHistoryBack } from '@/router/route.service';
import { getters } from '@/modules/user/user.store';

export default {
  name: 'City',
  inject: ['cityEditVm'],
  data() {
    return {
      performer: getters.getUser(),
      city: this.cityEditVm.city,
      city_anniversary: null,
    };
  },
  mounted() {
    this.formatDate();
  },
  methods: {
    goHistoryBack,
    formatDate() {
      this.city_anniversary = new Date(this.city.citys_anniversary).toJSON().slice(0, 10);
    },
    save() {
      this.city.citys_anniversary = this.city_anniversary;
      this.$emit('save');
    },
  },
};
</script>
